/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { ObjectId } from "mongodb";

/* Component Imports */

import Link from "@mui/material/Link";
import OFBCardImage from "./sub-components/OFBCardImage";
import OFBCardTitle from "./sub-components/OFBCardTitle";
import OFBCardContact from "./sub-components/OFBCardContact";
import OFBCardLocation from "./sub-components/OFBCardLocation";
import OFBCardType from "./sub-components/OFBCardType";
import { Chip } from "@mui/material";

/* Styled Components */

const OFBLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const OFBCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "23.125rem",
	margin: "0.1rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "23.125rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	[theme.breakpoints.down(413)]: {
		padding: "0.75rem 1.25rem 1rem 1.25rem",
	},
}));

const TitleAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "1rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor: "#ffc5c5",
	color: "#212121",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const OFBCardGridView = ({
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	property_category,
	property_type,
	title,
	images,
	location,
	saved_content,
	created_by,
	boosting,
	show_contact_details,
	source,
}: {
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	property_category: string;
	property_type: Array<string>;
	title: string;
	images: string;
	location: string;
	saved_content: Array<any>;
	created_by?: any;
	boosting: StateInterface["property"]["boost_information"];
	show_contact_details: boolean;
	source?: string;
}) => {
	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<OFBLink
				href={`/properties/${slug}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
			>
				<OFBCardContainer source={source ? source : ""}>
					{/* Image */}

					<OFBCardImage
						_id={_id}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						boosting={boosting}
					/>

					<TitleAndTransactionContainer>
						{/* Title */}

						<OFBCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>

						{/* Transaction Type Chip */}

						<CustomChip label="OFB" />
					</TitleAndTransactionContainer>

					<OFBCardInfoContainer>
						<CardRow>
							{/* OFB Type */}

							<OFBCardType
								loading={loading}
								propertyType={property_type?.length > 0 ? property_type : [property_category]}
							/>

							<VerticalLine />

							{/* Location */}

							<OFBCardLocation
								loading={loading}
								location={location !== "" || undefined ? location : "Unknown Location"}
							/>
						</CardRow>

						{/* Config and CTA */}

						<OFBCardContact
							loading={loading}
							dispatch={dispatch}
							session={session}
							slug={slug}
							title={title}
							created_by={created_by}
							show_contact_details={show_contact_details}
						/>
					</OFBCardInfoContainer>
				</OFBCardContainer>
			</OFBLink>
		</React.Fragment>
	);
};

export default OFBCardGridView;
